import { useAccessControl } from "hooks/useAccessControl";
import { Entity } from "types";
import { Avatar, Grid, Label, RisktrailScore } from "ui";

type Props = {
  entity: Entity;
  onClick?: () => void;
};

export const EntityItem = ({ entity, onClick }: Props) => {
  const { name, tag, riskScore } = entity;
  const hasAccess = useAccessControl("profiles");

  return (
    <Grid container item xs={12} columnSpacing={2} flexWrap="nowrap" onClick={onClick}>
      <Grid item>
        <Avatar src={`/images/entities/${name}.png`} size="normal"></Avatar>
      </Grid>
      <Grid container item xs={12} columnSpacing={1} direction="column">
        <Grid item>
          <b>{name}</b>
        </Grid>
        <Grid item>
          <Label>{tag}</Label>
        </Grid>
      </Grid>
      {hasAccess && (
        <Grid item>{riskScore && <RisktrailScore score={riskScore} value={riskScore} justifyContent="center" />}</Grid>
      )}
    </Grid>
  );
};
export default EntityItem;
