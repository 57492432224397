import { ConfirmPopup } from "ui";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const CancelSubscription = ({ onConfirm, onCancel }: Props) => (
  <ConfirmPopup title="Cancel subscriptin" confirmText="Confirm cancellation" onCancel={onCancel} onConfirm={onConfirm}>
    Do you really want to cancel your subscription?
  </ConfirmPopup>
);

export default CancelSubscription;
