import axios from "axios";
import { useEffect, useState } from "react";
import { Metadata, NationFlag, PaymentBanner, Summary } from "components";
import { Hack } from "types/Events";
import { TokenTags } from "../TokensTags";
import { EntityDetail } from "../EntityDetail";
import { Divider, Grid, Label, Link, Loading, Title } from "ui";
import EventBadge from "components/EventBadge";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useModal } from "contexts/ModalContext";
import { useErrorHandler } from "hooks";
import { useAccessControl } from "hooks/useAccessControl";
import React from "react";

const HackDetails = ({ hackId }: { hackId?: string | number }) => {
  const handleError = useErrorHandler();
  const hasAccess = useAccessControl("profiles");

  const { setIsLoading, isLoading } = useModal();
  const theme = useTheme();
  const [details, setDetails] = useState<Hack>();

  const params = useParams();

  const hackIDToLoad = hackId || params.hackId;

  useEffect(() => {
    const fetchHackDetails = async () => {
      setIsLoading(true);

      try {
        const { data } = await axios.get(`/api/entities/hacks/${hackIDToLoad}`);
        setDetails(data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (hackIDToLoad) {
      fetchHackDetails();
    }

    return () => {
      setIsLoading(false);
    };
  }, [hackIDToLoad]);

  if (isLoading || !details) {
    return <Loading />;
  }
  return (
    <Grid container direction="column" rowGap={3} paddingBottom={8}>
      <PaymentBanner
        hasAccess={hasAccess}
        title="Unlock detailed event reports"
        description="Upgrade your plan to gain access to detailed reports on scams, sanctions and hacks."
      />
      <Grid container rowGap={2} direction="column">
        <Grid container justifyContent={"space-between"}>
          <EventBadge type={"Hack"} theme={theme} />
        </Grid>
        <Grid container rowGap={1}>
          <Title>{details?.["Event Description"]}</Title>
          <Label fontWeight={600}>
            {details &&
              new Date(details.Date).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
          </Label>
        </Grid>
      </Grid>
      <Grid container>
        <Label>Entity</Label>
      </Grid>
      <EntityDetail name={details?.Entity} tag={details?.Type} />
      <Grid container>
        <Metadata
          spacing={3}
          list={[
            {
              title: "Event",
              value: details?.["Event"],
            },
            {
              title: "Est. Amount Stolen",
              value: details?.["Est. Amount Stolen"]
                ? `$ ${Number(details?.["Est. Amount Stolen"]).toLocaleString("en-gb", {
                    maximumFractionDigits: 2,
                  })}`
                : "-",
            },
            {
              title: "Jurisdiction",
              value: (
                <Grid item container flexWrap="nowrap" columnGap={1}>
                  <Grid item>
                    <NationFlag countryName={details?.["Jurisdiction"]} />
                  </Grid>
                  <Grid item>{details?.["Jurisdiction"]}</Grid>
                </Grid>
              ),
            },
            {
              title: "Perpetrator(s)",
              value: details?.["Perpetrator(s)"],
            },
          ]}
        />
      </Grid>
      <Grid container>
        <Grid container direction="column">
          <Grid>
            <Label>Tokens</Label>
          </Grid>
          <Grid container>
            <TokenTags tokens={details?.Tokens} />
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      <Summary text={details?.Synopsis ?? ""} showAll={true} />
      {details?.Link && (
        <Link href={details?.Link} target="_blank">
          View Source
        </Link>
      )}
    </Grid>
  );
};

export default HackDetails;
