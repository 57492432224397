import { useContext, useState } from "react";
import * as S from "./styled";
import { Button, Grid, Label, Loading, PaymentStar, Title } from "ui";
import { useErrorHandler } from "hooks";
import axios from "axios";
import { toast } from "react-toastify";
import PaymentConfirmation from "./PaymentConfirmation";
import { AppContext } from "contexts";

type Props = {
  title: string;
  hasAccess: boolean;
  parentType?: "page" | "pupup";
  description?: string;
  style?: React.CSSProperties;
};

const PaymentBanner = ({ title, hasAccess, description, parentType = "page", style }: Props) => {
  const { isPaid, isPaymentLoading, setIsPaymentConfirmation } = useContext(AppContext);
  const [isUpgradeRequested, setIsUpgradeRequested] = useState(false);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const email = "info@hoptrail.io";
    const mailtoLink = document.createElement("a");
    mailtoLink.href = `mailto:${email}`;
    mailtoLink.click();
  };

  const handleUpgradeRequest = async () => {
    try {
      await axios.post("/api/users/request-upgrade");
      setIsUpgradeRequested(true);
      toast.success("Your upgrade request has been sent!");
    } catch (error) {
      toast.error("Failed to send upgrade request.");
    }
  };

  if (hasAccess) {
    return null;
  }

  return (
    <S.Wrapper parentType={parentType} style={style}>
      {isPaymentLoading ? (
        <Grid container justifyContent="center" alignItems="center">
          <Loading variant="small" />
        </Grid>
      ) : (
        <>
          <PaymentStar />
          <Grid container alignItems="center" direction="column" gap={1.5}>
            <Title variant="small" textAlign="center">
              {title}
            </Title>
            {description && <Label variant="small">{description}</Label>}
          </Grid>
          <Button
            style={{
              width: "192px",
              height: "48px",
              textTransform: "capitalize",
              fontSize: "16px",
              textDecoration: parentType === "pupup" ? "underline" : "none",
            }}
            buttonTheme="primary"
            onClick={() => handleUpgradeRequest()}
            variant={parentType === "pupup" ? "text" : "contained"}
            disabled={isPaid || isPaymentLoading}
          >
            {" "}
            {isUpgradeRequested ? "Upgrade Requested!" : "Request Upgrade"}{" "}
          </Button>{" "}
        </>
      )}{" "}
    </S.Wrapper>
  );
};
export default PaymentBanner;
