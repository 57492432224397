import { Divider, Link, useTheme } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Metadata, PaymentBanner, Section, Summary } from "../../../components";
import { Scam } from "../../../types/Events";
import { TokenTags } from "../TokensTags";
import { formatFiatValue } from "utils";
import { Grid, Label, Loading, Title } from "ui";
import EventBadge from "components/EventBadge";
import { useParams } from "react-router-dom";
import { useModal } from "contexts/ModalContext";
import { useErrorHandler } from "hooks";
import { useAccessControl } from "hooks/useAccessControl";

const ScamDetail = ({ scamId }: { scamId?: string | number }) => {
  const handleError = useErrorHandler();
  const hasAccess = useAccessControl("profiles");

  const theme = useTheme();
  const { setIsLoading, isLoading } = useModal();
  const [details, setDetails] = useState<Scam>();

  const params = useParams();
  const scamIDToLoad = scamId || params.scamId;

  useEffect(() => {
    const fetchHackDetails = async () => {
      setIsLoading(true);

      try {
        const { data } = await axios.get(`/api/entities/scams/${scamIDToLoad}`);
        setDetails(data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (scamIDToLoad) {
      fetchHackDetails();
    }

    return () => {
      setIsLoading(false);
    };
  }, [scamIDToLoad]);

  if (isLoading || !details) {
    return <Loading />;
  }
  return (
    <Grid container direction="column" rowGap={3} paddingBottom={8}>
      <PaymentBanner
        hasAccess={hasAccess}
        title="Unlock detailed event reports"
        description="Upgrade your plan to gain access to detailed reports on scams, sanctions and hacks."
      />
      <Grid container rowGap={2} direction="column">
        <Grid container justifyContent={"space-between"}>
          <EventBadge type={"Scam"} theme={theme} />
        </Grid>
        <Grid container rowGap={1}>
          <Title>{details?.["Event Description"]}</Title>
          <Label fontWeight={600}>
            {details &&
              `${new Date(details["Start Date"]).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })} - ${new Date(details["End Date"]).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}`}
          </Label>
        </Grid>
      </Grid>
      <Metadata
        spacing={3}
        list={[
          {
            title: "Entity",
            value: details?.["Entity"],
          },
          {
            title: "Type",
            value: details?.["Type"],
          },

          {
            title: "Jurisdiction",
            value: details?.Jurisdiction && details?.["Jurisdiction"],
          },
          {
            title: "Tokens",
            value: <TokenTags tokens={details?.Tokens} />,
          },
          {
            title: "Est. Amount",
            value: details && details["Est. Amount"] ? `$${formatFiatValue(details["Est. Amount"] as number)}` : "",
          },
          {
            title: "Perpetrator(s)",
            value: details?.["Perpetrator(s)"] && details?.["Perpetrator(s)"].split(","),
          },
        ]}
      />
      <Divider />

      <Summary text={details?.Synopsis ?? ""} showAll={true} />

      {details?.Link && (
        <Section>
          <Link href={details?.Link} target="_blank">
            View Source
          </Link>
        </Section>
      )}
    </Grid>
  );
};

export default ScamDetail;
