const PaymentStar = () => (
  <svg width="64" height="70" viewBox="0 0 64 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4409_1059)">
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73823 19.1897C8.64348 15.3312 5.47996 12.2309 1.59888 12.2309H1.58988C0.804617 12.2264 0.168316 11.5901 0.168316 10.8003C0.168316 10.0151 0.809134 9.37423 1.59888 9.37423C5.54312 9.37423 8.74275 6.17017 8.74275 2.22588C8.74275 1.44065 9.38356 0.799805 10.1733 0.799805C10.9585 0.799805 11.5994 1.44065 11.5994 2.22588C11.5994 6.17017 14.8035 9.37423 18.7477 9.37423C19.533 9.37423 20.1738 10.0151 20.1738 10.8003C20.1738 11.5901 19.533 12.2309 18.7477 12.2309C14.8622 12.2309 11.6987 15.3312 11.6039 19.1897L11.5994 19.3747C11.5994 20.1645 10.9585 20.8053 10.1733 20.8053C9.38356 20.8053 8.74275 20.1645 8.74275 19.3747L8.73823 19.1897Z"
        fill="#DA016D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.8317 39.2297C63.8317 40.6099 62.7117 41.7298 61.3394 41.7298C47.5532 41.7298 36.3617 52.9213 36.3617 66.6997C36.3617 68.0799 35.2418 69.1998 33.8615 69.1998C32.4892 69.1998 31.3693 68.0799 31.3693 66.6997L31.3614 66.3763C31.1958 52.7399 20.0674 41.7298 6.39152 41.7298H6.37573C5.00342 41.7219 3.89139 40.602 3.89139 39.2297C3.89139 37.8495 5.01131 36.7295 6.39152 36.7295H6.47037C20.2172 36.6901 31.3693 25.5144 31.3693 11.7596C31.3693 10.3794 32.4892 9.25952 33.8615 9.25952C35.2418 9.25952 36.3617 10.3794 36.3617 11.7596V11.8385C36.409 25.5853 47.5768 36.7295 61.3394 36.7295C62.7117 36.7295 63.8317 37.8495 63.8317 39.2297Z"
        fill="#DA016D"
      />
      <path
        opacity="0.45"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.0052 20.0318C60.0052 20.7129 59.4565 21.2616 58.7793 21.2655H58.7716C56.7324 21.2655 55.0746 22.9233 55.0746 24.9624C55.0746 25.6434 54.522 26.196 53.8449 26.196C53.1639 26.196 52.6113 25.6434 52.6113 24.9624V24.869C52.5607 22.8727 50.9223 21.2655 48.9143 21.2655C48.2333 21.2655 47.6807 20.7129 47.6807 20.0318C47.6807 19.3547 48.2333 18.8021 48.9143 18.8021C50.9535 18.8021 52.6113 17.1443 52.6113 15.1052C52.6113 14.4241 53.1639 13.8716 53.8449 13.8716L54.3236 13.9689C54.6193 14.0934 54.8528 14.3308 54.9773 14.6226L55.3665 16.5412C55.9269 17.8682 57.2422 18.8021 58.7716 18.8021C59.4526 18.8021 60.0052 19.3547 60.0052 20.0318Z"
        fill="#DA016D"
      />
    </g>
    <defs>
      <clipPath id="clip0_4409_1059">
        <rect width="64" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PaymentStar;
