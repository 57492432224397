import { Tabs, Tab, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import axiosRetry from "axios-retry";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import type { Entity, EntityProfileDetails } from "types";
import { Grid, Loading, Chip } from "ui";
import { PaymentBanner, Summary } from "..";
import Licensing from "./Licensing";
import Header from "./Header";
import MoreInfo from "./MoreInfo";
import EntityProfilePDF from "./EntityProfilePDF";

import Overview from "./Overview";
import { useErrorHandler } from "hooks";
import { useModal } from "contexts/ModalContext";
import { useAccessControl } from "hooks/useAccessControl";

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    if (error.config && error.config.url) {
      return error.config.url.includes("api/entities/") && error.config.url.endsWith("/reserves");
    }
    return false;
  },
});

type Props = {
  entity: Entity;
};

export const EntityProfile = ({ entity }: Props) => {
  const { setIsLoading, isLoading } = useModal();
  const hasAccess = useAccessControl("profiles");

  const componentRef = useRef<HTMLDivElement>(null);
  const handleError = useErrorHandler();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [entityProfileDetails, setEntityProfileDetails] = useState<EntityProfileDetails>({
    volume: 0,
    reservers: null,
    solvency: null,
    regulatory: [],
  });
  const [print, setPrint] = useState(false);
  const [tab, setTab] = useState(0);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${entity.name} Profile`,
    onBeforePrint: () => {
      setPrint(true);
    },
    onAfterPrint: () => {
      setPrint(false);
    },
  });

  useEffect(() => {
    if (print) {
      handlePrint();
    }
  }, [print]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const profilePromise = [axios(`/api/entities/${encodeURIComponent(entity.name)}/profile`)];
        if (entity.tag.includes("DEX") || entity.tag.includes("Exchange")) {
          profilePromise.push(axios(`/api/entities/${encodeURIComponent(entity.name)}/tradevolume`));
        }
        const [profile, volume] = await Promise.all(profilePromise);
        setEntityProfileDetails({
          solvency: profile.data.solvency,
          reservers: profile.data.reserves,
          regulatory: profile.data.regulatory,
          volume: volume ? volume.data : null,
        });
      } catch {
        handleError("Error while loading entity profile");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [entity.name]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      sx={{
        minHeight: "900px",
        minWidth: isSmallScreen ? "initial" : "1000px",
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container direction="column" justifyContent="space-between">
          <PaymentBanner
            hasAccess={hasAccess}
            title="Unlock detailed entity profiles"
            description="Upgrade your plan to gain access to detailed profiles with security and integrity evaluation and licensing."
          />
          <Grid container direction="column">
            <Header
              entity={entity}
              handlePrint={() => {
                setPrint(true);
                handlePrint();
              }}
            />
            <Tabs
              value={tab}
              onChange={handleTabChange}
              textColor="inherit"
              sx={(theme) => {
                return {
                  color: "black",
                  borderBottom: `2px solid ${theme.colors.mediumGray}`,
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                  "& .MuiTabs-indicator": {
                    height: "4px",
                  },
                };
              }}
            >
              <Tab label="Overview" />
              <Tab label="Integrity Check" />
              <Tab
                label={
                  <Grid item container columnGap={1} alignItems="center">
                    <Grid> Licensing</Grid>
                    <Grid>
                      <Chip
                        label={entityProfileDetails.regulatory.length}
                        sx={(theme) => {
                          return {
                            border: `1px solid ${theme.colors.darkGray}`,
                            height: "20px",
                          };
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <Tab label="About" />
            </Tabs>
            <Grid container marginTop={5}>
              {tab === 0 && (
                <Overview
                  entity={entity}
                  volume={entityProfileDetails.volume}
                  reservers={entityProfileDetails.reservers}
                  solvency={entityProfileDetails.solvency}
                  print={false}
                />
              )}
              {tab === 1 && entity.status !== "Inactive" && entity.integrityCheck && (
                <Grid container justifyContent="center">
                  <Grid container width="650px" paddingTop={2}>
                    <Summary text={entity.integrityCheck} variant="list" limit={1000} />
                  </Grid>
                </Grid>
              )}
              {tab === 2 && <Licensing licences={entityProfileDetails.regulatory} print={false} />}
              {tab === 3 && entity.description && (
                <Grid container justifyContent="center">
                  <Grid container width="500px">
                    <Summary text={entity.description} limit={1000} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <MoreInfo isSmallScreen={isSmallScreen} entityName={entity.name} />
          {print && (
            <EntityProfilePDF
              entity={entity}
              ref={componentRef}
              reservers={entityProfileDetails.reservers}
              solvency={entityProfileDetails.solvency}
              regulatory={entityProfileDetails.regulatory}
              volume={entityProfileDetails.volume}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default EntityProfile;
