import { Logout, Tune } from "@mui/icons-material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Menu, IconButton as IconButtonUI, Divider } from "@mui/material";
import { useState } from "react";
import { Grid, SearchIcon, SideMenuIcon } from "ui";
import { MenuItem, SearchBar } from "components";
import { Entity } from "types";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
type Props = {
  isLandingPage?: boolean;
  entities: Entity[];
};

export const TopBarSmall = ({ isLandingPage = false, entities }: Props) => {
  const { logout } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [eventsMenuAnchor, setEventsMenuAnchor] = useState<null | HTMLElement>(null);

  return (
    <Grid container item justifyContent={searchOpen ? "space-between" : "flex-end"} flexWrap="nowrap">
      {!isLandingPage && searchOpen && (
        <Grid item container flex-wrap="nowrap" justifyContent="center">
          <SearchBar
            placeholder="Search BTC or ETH address, entity..."
            onChange={(searchTerm: string) => {
              setSearchTerm(searchTerm);
            }}
            options={entities.filter((entity) => entity.status !== "Inactive").map((entity: Entity) => entity.name)}
            value={searchTerm}
            isSmallScreen={true}
            onSearchCancel={() => setSearchOpen(false)}
          />
        </Grid>
      )}
      <Grid item container flex-wrap="nowrap" justifyContent="flex-end" width="fit-content">
        {!searchOpen && !isLandingPage && (
          <Grid item>
            <IconButtonUI onClick={() => setSearchOpen(true)}>
              <SearchIcon />
            </IconButtonUI>
          </Grid>
        )}
        <Grid item>
          <IconButtonUI
            onClick={(e) => {
              setEventsMenuAnchor(e.currentTarget);
              setMenuOpen(true);
            }}
          >
            <SideMenuIcon />
          </IconButtonUI>
        </Grid>
      </Grid>
      <Menu
        disableScrollLock={true}
        open={menuOpen}
        anchorEl={eventsMenuAnchor}
        onClose={() => setMenuOpen(false)}
        slotProps={{
          paper: {
            style: {
              width: 200,
            },
          },
        }}
      >
        <MenuItem path="/leaderboard" label="Leaderboard" />
        <MenuItem
          path="/events/hacks"
          label="Hacks"
          onClick={() => {
            setMenuOpen(false);
            navigate("/events/hacks");
          }}
        />
        <MenuItem
          path="/events/sanctions"
          label="Sanctions"
          onClick={() => {
            setMenuOpen(false);
            navigate("/events/sanctions");
          }}
        />
        <MenuItem
          path="/events/scams"
          label="Scams"
          onClick={() => {
            setMenuOpen(false);
            navigate("/events/scams");
          }}
        />
        <Divider />
        <MenuItem
          path="/alerts"
          label="Alerts"
          onClick={() => {
            setMenuOpen(false);
            navigate("/alerts");
          }}
        />
        <MenuItem
          path="/portfolio"
          label="Portfolio"
          onClick={() => {
            setMenuOpen(false);
            navigate("/portfolio");
          }}
        />
        <Divider />
        <MenuItem
          path="/guide"
          label="User Guide"
          icon={<NotificationsNoneIcon />}
          onClick={() => {
            setMenuOpen(false);
            navigate("/guide");
          }}
        />
        <MenuItem
          path="/profile"
          label="Profile"
          icon={<Tune />}
          onClick={() => {
            setMenuOpen(false);
            navigate("/settings");
          }}
        />
        <MenuItem
          path=""
          label="Logout"
          icon={<Logout />}
          onClick={() => {
            setMenuOpen(false);
            logout({
              logoutParams: { returnTo: window.location.origin },
            });
          }}
        />
      </Menu>
    </Grid>
  );
};

export default TopBarSmall;
