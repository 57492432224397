import { ConfirmPopup } from "ui";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const PaymentConfirmation = ({ onConfirm, onCancel }: Props) => {
  return (
    <ConfirmPopup title="Upgrade plan" confirmText="Upgrade" onCancel={() => onCancel()} onConfirm={onConfirm}>
      Do you really want to upgrade your plan?
    </ConfirmPopup>
  );
};

export default PaymentConfirmation;
