import { useContext, useEffect, useState } from "react";
import { Title, Grid, Avatar, BitcoinIcon, EthereumIcon, Box, LightBulbIcon, Loading } from "ui";
import AuthContext from "contexts/Auth0Context";
import NotificationTitle from "./NotificationTitle";
import axios from "axios";
import { useErrorHandler } from "hooks";
import Notification from "./Notification";
import {
  AddressNotification,
  AddressNotificationLog,
  AddressNotificationType,
  EntityNotification,
  EntityNotificationLog,
} from "types";
import { Address, PaymentBanner } from "components";
import AddressNotifications from "./AddressNotifications";
import EntitiesNotifications from "./EntitiesNotifications";
import { useModal } from "contexts/ModalContext";
import { validateAddress } from "utils/address";
import { useAccessControl } from "hooks/useAccessControl";

function Notifications() {
  const { user } = useContext(AuthContext);
  const { openModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [watchedEntities, setWatchedEntities] = useState<EntityNotification[]>([]);
  const [entitiesNotificationLogs, setEntitiesNotificationLogs] = useState<EntityNotificationLog[]>([]);
  const [watchedAddresses, setWatchedAddresses] = useState<AddressNotification[]>([]);
  const [addressNotificationLogs, setAddressNotificationLogs] = useState<AddressNotificationLog[]>([]);
  const handleError = useErrorHandler();
  const hasEntitiesAccess = useAccessControl("profiles");
  const hasAddressAccess = useAccessControl("reports");

  useEffect(() => {
    const markNotificationsAsRead = async () => {
      try {
        await axios.put("/api/notifications/mark-all-as-read");
      } catch (e) {
        handleError(e);
      }
    };

    const fetchNotifications = async () => {
      try {
        setIsLoading(true);
        const [watchedAddressesResponse, addressNotificationLogsResponse, entitiesResponse, logsResponse] =
          await Promise.all([
            axios.get<AddressNotification[]>("/api/notifications/addresses"),
            axios.get<AddressNotificationLog[]>("/api/notifications/addresses/logs"),
            axios.get<EntityNotification[]>("/api/notifications/entities"),
            axios.get<EntityNotificationLog[]>("/api/notifications/entities/logs"),
          ]);
        setWatchedAddresses(watchedAddressesResponse.data);
        setAddressNotificationLogs(addressNotificationLogsResponse.data);
        setWatchedEntities(entitiesResponse.data);
        setEntitiesNotificationLogs(logsResponse.data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
    markNotificationsAsRead();
  }, []);

  const renderNotificationsHistory = (type: AddressNotificationType | "entity") => {
    if (type === "entity") {
      return <EntitiesNotifications entitiesNotificationLogs={entitiesNotificationLogs} />;
    } else {
      return <AddressNotifications addressNotificationLogs={addressNotificationLogs} notificationType={type} />;
    }
  };

  const showNotificationHistory = (type: AddressNotificationType | "entity") => {
    openModal(
      <Grid container marginTop={10} paddingBottom={6}>
        {renderNotificationsHistory(type)}
      </Grid>,
      "Alerts History",
      { fitContent: true },
    );
  };

  const removeNotification = async (notificationType: AddressNotificationType | "entity", value: string | number) => {
    try {
      if (notificationType === "transfer" || notificationType === "high-risk") {
        await axios.post("/api/notifications/addresses", {
          address: value,
          type: notificationType,
          enable: false,
        });
        // remove the notification with address of `value` and given type `notificationType` from the list
        setWatchedAddresses(
          watchedAddresses.filter(
            (ad) => ad.address !== value || (ad.address === value && ad.type !== notificationType),
          ),
        );
      } else {
        await axios.post("/api/notifications/entities", { entity_id: value, enable: false });
        setWatchedEntities(watchedEntities.filter((e) => e.entity_id !== value));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getLastTriggeredForEntity = (id: number) => {
    const log = entitiesNotificationLogs.find((log) => log.entity_id === id);
    return log ? new Date(log.created_at) : null;
  };

  const getLastTriggeredForAddress = (type: AddressNotificationType, address: string) => {
    const latestAddressTriggerByTxDate = addressNotificationLogs
      .filter((log) => log.address === address && log.type === type)
      .sort((a, b) => {
        return new Date(b.additionalInfo.timestamp).getTime() - new Date(a.additionalInfo.timestamp).getTime();
      })[0];

    return latestAddressTriggerByTxDate ? new Date(latestAddressTriggerByTxDate.additionalInfo.timestamp) : null;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container direction="column" maxWidth={1000} marginX="auto" marginTop={5} rowGap={7}>
      <Grid item>
        <Title>Alerts</Title>
      </Grid>
      <Grid
        container
        sx={(theme) => ({ background: theme.colors.lightGray, color: theme.colors.darkGray })}
        padding={2}
        borderRadius="12px"
        flexWrap="nowrap"
        alignItems="center"
        columnGap={2}
      >
        <Grid item>
          <LightBulbIcon color="darkGrey" />
        </Grid>
        <Grid item xs={11}>
          To set up new alerts, simply click the <strong>Add Alert</strong> icon in the entity or address profile.
        </Grid>
      </Grid>
      <Grid container item rowGap={2} position="relative" minHeight={!hasEntitiesAccess ? "290px" : undefined}>
        <NotificationTitle
          hasAccess={hasEntitiesAccess}
          title="AML score changes"
          userEmail={user.email}
          notificationCount={watchedEntities.length}
          onHistoryClick={() => showNotificationHistory("entity")}
        />
        <PaymentBanner
          hasAccess={hasEntitiesAccess}
          title="Upgrade your plan to get access to recent entities alerts."
          style={{
            background: "rgba(246, 246, 247, 0.20)",
            backdropFilter: " blur(20px)",
            top: "51px",
            padding: "20px",
          }}
        />
        {watchedEntities.length > 0 && (
          <Grid container item>
            {watchedEntities.map(({ entity, entity_id }) => (
              <Grid xs={12} md={6} item key={entity}>
                <Box marginRight={1} marginTop={1}>
                  <Notification
                    icon={<Avatar src={`/images/entities/${entity.trim()}.png`} size="small"></Avatar>}
                    title={entity}
                    lastTriggered={getLastTriggeredForEntity(entity_id)}
                    removeNotification={() => removeNotification("entity", entity_id)}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid container item rowGap={2} position="relative" minHeight={!hasAddressAccess ? "290px" : undefined}>
        <NotificationTitle
          hasAccess={hasAddressAccess}
          title="address interacts with a high-risk wallet"
          userEmail={user.email}
          notificationCount={watchedAddresses.filter(({ type }) => type === "high-risk").length}
          onHistoryClick={() => showNotificationHistory("high-risk")}
        />
        <PaymentBanner
          hasAccess={hasAddressAccess}
          title="Upgrade your plan to get access to recent address alerts."
          style={{
            background: "rgba(246, 246, 247, 0.20)",
            backdropFilter: " blur(20px)",
            top: "51px",
            height: "max-content",
            padding: "20px",
          }}
        />
        {watchedAddresses.length > 0 && (
          <Grid container>
            {watchedAddresses
              .filter(({ type }) => type === "high-risk")
              .map(({ address, type }) => (
                <Grid xs={12} md={6} item key={address}>
                  <Box marginRight={1} marginTop={1}>
                    <Notification
                      icon={validateAddress(address, "eth") ? <EthereumIcon /> : <BitcoinIcon />}
                      title={
                        <Address
                          link={`/report/${validateAddress(address, "eth") ? "eth" : "btc"}/${address}`}
                          address={address}
                          variant="compact"
                        />
                      }
                      lastTriggered={getLastTriggeredForAddress(type, address)}
                      removeNotification={() => removeNotification(type, address)}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
      <Grid container item rowGap={2} position="relative" minHeight={!hasAddressAccess ? "290px" : undefined}>
        <NotificationTitle
          hasAccess={hasAddressAccess}
          title="address receives or sends funds"
          userEmail={user.email}
          notificationCount={watchedAddresses.filter(({ type }) => type === "transfer").length}
          onHistoryClick={() => showNotificationHistory("transfer")}
        />
        <PaymentBanner
          hasAccess={hasAddressAccess}
          title="Upgrade your plan to get access to recent address alerts."
          style={{
            background: "rgba(246, 246, 247, 0.20)",
            backdropFilter: " blur(20px)",
            top: "51px",
            height: "max-content",
            padding: "20px",
          }}
        />
        {watchedAddresses.length > 0 && (
          <Grid container>
            {watchedAddresses
              .filter(({ type }) => type === "transfer")
              .map(({ address, type }) => (
                <Grid xs={12} md={6} item key={address}>
                  <Box marginRight={1} marginTop={1}>
                    <Notification
                      icon={validateAddress(address, "eth") ? <EthereumIcon /> : <BitcoinIcon />}
                      title={
                        <Address
                          link={`/report/${validateAddress(address, "eth") ? "eth" : "btc"}/${address}`}
                          address={address}
                          variant="compact"
                        />
                      }
                      lastTriggered={getLastTriggeredForAddress(type, address)}
                      removeNotification={() => removeNotification(type, address)}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Notifications;
