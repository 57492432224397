import { ArrowDownwardIcon, ArrowUpwardIcon, Chip, Grid } from "ui";
import { isDateWithingPeriod } from "utils";
import type { Entity } from "types";

type Props = Pick<Entity, "riskScore" | "previousScore" | "lastScoreUpdatedAt">;

const ScoreTrend: React.FC<Props> = ({ riskScore, previousScore, lastScoreUpdatedAt }) => {
  const hasScoreChanged = previousScore !== null && lastScoreUpdatedAt !== null && riskScore !== previousScore;

  if (!hasScoreChanged || !isDateWithingPeriod(lastScoreUpdatedAt, 28)) {
    return null;
  }

  const isIncreasing = riskScore > previousScore;
  const scoreDiff = Math.abs(riskScore - previousScore).toFixed(2);
  const trendColor = isIncreasing ? "success" : "error";

  return (
    <Grid container alignItems="center" columnGap={1}>
      {isIncreasing ? <ArrowUpwardIcon color={trendColor} /> : <ArrowDownwardIcon color={trendColor} />}
      <Chip size="small" color={trendColor} variant="outlined" label={scoreDiff} />
    </Grid>
  );
};

export default ScoreTrend;
