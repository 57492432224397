import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider, Theme as MuiTheme } from "@mui/material";
import axios from "axios";
import React from "react";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { FlattenSimpleInterpolation } from "styled-components";
import { colors, fonts } from "styles/variables";
import type { Colors } from "styles/variables";
import App from "./App";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import "inter-ui/inter.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

type CustomTheme = {
  colors: Colors;
  fonts: {
    h1: FlattenSimpleInterpolation;
    h2: FlattenSimpleInterpolation;
    h3: FlattenSimpleInterpolation;
    h4: FlattenSimpleInterpolation;
    p1: FlattenSimpleInterpolation;
    p2: FlattenSimpleInterpolation;
    subText: FlattenSimpleInterpolation;
    subTextDarkGray: FlattenSimpleInterpolation;
    subTitleWhite: FlattenSimpleInterpolation;
    text1: FlattenSimpleInterpolation;
    label: FlattenSimpleInterpolation;
    darkLabel: FlattenSimpleInterpolation;
  };
};

declare global {
  type Theme = MuiTheme;
}
declare module "@mui/material" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/consistent-type-definitions
  export interface Theme extends CustomTheme {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/consistent-type-definitions
  export interface ThemeOptions extends CustomTheme {}
}

// https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/69006
declare module "react" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLAttributes<T> {
    onPointerEnterCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerLeaveCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerMoveCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerOutCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerOverCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerEnter?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerLeave?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerMove?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerOut?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerOver?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface RefAttributes<T> {
    onPointerEnterCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerLeaveCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerMoveCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerOutCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerOverCapture?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerEnter?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerLeave?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerMove?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerOut?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onPointerOver?: (e: React.PointerEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  }
}

// Consolidate axios interceptors
axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 402 && error.response?.data?.checkoutUrl) {
      window.location.href = error.response.data.checkoutUrl;
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    allVariants: {
      color: "#000",
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      dark: colors.darkGray,
      main: colors.secondary,
      light: colors.lightGray,
    },
    error: {
      dark: colors.darkRed,
      main: colors.error,
      light: colors.lightRed,
    },
    success: {
      dark: colors.darkGreen,
      main: colors.success,
      light: colors.lightGreen,
    },
    info: {
      dark: colors.blue,
      main: colors.blue,
      light: colors.lightBlue,
    },
    warning: {
      dark: colors.darkOrange,
      main: colors.warning,
      light: colors.lightOrange,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            borderColor: colors.darkGray,
          },
        },
        {
          props: { variant: "outlined", color: "warning" },
          style: {
            borderColor: colors.darkRed,
            background: colors.lightRed,
            color: colors.black,
          },
        },
        {
          props: { variant: "outlined", color: "success" },
          style: {
            borderColor: colors.green,
            background: colors.lightGreen,
            color: colors.black,
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: "1250px",
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "48px",
        },
        multiline: {
          height: "auto",
        },
      },
    },
  },
  colors,
  fonts,
});
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>,
);
