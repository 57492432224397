import { LabelValue } from "components";
import { Hack } from "types/Events";
import { Chip, Grid } from "ui";
import { formatFiatValue } from "utils";
import React from "react";
import { useAccessControl } from "hooks/useAccessControl";

type Props = {
  hack: Hack;
};
export const HackItem: React.FC<Props> = ({ hack }: Props) => {
  const hasAccess = useAccessControl("profiles");

  return (
    <Grid container item direction="column" rowGap={1} padding={2}>
      <Grid item>
        <Chip
          label="Hack"
          sx={(theme) => ({
            borderRadius: "4px",
            backgroundColor: theme.colors.lightOrange,
            color: theme.colors.darkOrange,
            fontWeight: "bold",
            padding: "1px 0px 1px 0px",
            height: "20px",
            fontSize: "12px",
          })}
          size="small"
        />
      </Grid>
      <Grid>
        <b>{hack["Event Description"]}</b>
      </Grid>
      {hasAccess && (
        <Grid container item direction="column" rowGap={2}>
          <Grid container item>
            <Grid sm={4}>
              <LabelValue label="Date" labelColor="darkGray" value={hack.Date} valueBold={true} width={250} />
            </Grid>
            <Grid sm={4}>
              <LabelValue labelColor="darkGray" label="Entity Type" value={hack.Type} valueBold={true} width={250} />
            </Grid>
            <Grid sm={4}>
              <LabelValue
                label="Est. Lost"
                labelColor="darkGray"
                value={hack["Est. Amount Stolen"] ? `$${formatFiatValue(Number(hack["Est. Amount Stolen"]))}` : ""}
                valueBold={true}
                width={250}
              />
            </Grid>
          </Grid>
          {/* <Grid item style={{ textOverflow: "ellipsis" }}>
        {shortenSynopsis(hack.Synopsis)}
      </Grid> */}
        </Grid>
      )}
    </Grid>
  );
};

export default HackItem;
