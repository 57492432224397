import styled from "styled-components";

export const Wrapper = styled.div<{ background?: string; parentType?: "page" | "pupup" }>`
  position: absolute;
  top: ${(props) => (props.parentType === "page" ? "100px" : 0)};
  left: 0;
  width: 100%;
  height: -webkit-fill-available;
  background-color: white;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgb(255 255 255 / 29%) 0%, rgb(255, 255, 255) 30%);
  flex-direction: column;
  gap: ${(props) => (props.parentType === "page" ? "40px" : "7px")};
`;
