import { ProductType } from "types/User";
export type FeatureType = "profiles" | "reports" | "portfolio";

// Access control function
export const checkAccess = (product: ProductType, feature: FeatureType): boolean => {
  // Access map based on product types and available features
  const accessMap: Record<ProductType, Set<FeatureType>> = {
    FULL_MONTHLY: new Set(["profiles", "reports", "portfolio"]),
    FULL_YEARLY: new Set(["profiles", "reports", "portfolio"]),
    DILIGENCE_MONTHLY: new Set(["profiles"]),
    DILIGENCE_YEARLY: new Set(["profiles"]),
    SCREENING_MONTHLY: new Set(["reports", "portfolio"]),
    SCREENING_YEARLY: new Set(["reports", "portfolio"]),
  };

  // Check if the product has access to the feature
  return accessMap[product as ProductType]?.has(feature) ?? false;
};
