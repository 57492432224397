import { useContext, useEffect, useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useErrorHandler } from "hooks";
import { useSearchParams } from "react-router-dom";
import { Grid, Title } from "ui";
import PortfolioDetail from "./PortfolioDetail";
import { Report as EthReport } from "types/ethereum";
import { RenamePortfolio, DeletePortfolio, CreatePortfolio } from "./PortfolioActions";
import PortfolioActionBar from "./PortfolioActionBar";
import { PaymentBanner, Section } from "components";
import AuthContext from "contexts/Auth0Context";
import AppContext from "contexts/AppContext";
import { Report, Transaction } from "types";
import PortfolioDetailPrint from "./PortfolioDetailPrint";
import { useReactToPrint } from "react-to-print";
import { useAccessControl } from "hooks/useAccessControl";

export const Portfolio = () => {
  const [params] = useSearchParams();
  const hasAccess = useAccessControl("portfolio");
  const [print, setPrint] = useState(false);
  const componentRef = useRef(null);
  const { user, updateNumberOfPortfolios } = useContext(AuthContext);
  const { portfolios, updatePortfolios } = useContext(AppContext);
  const portfolioQueryParam = params.get("portfolio");
  let defaultPortfolio;
  if (portfolioQueryParam) {
    defaultPortfolio = portfolios.find((portfolio) => portfolio.id === Number(portfolioQueryParam));
  } else {
    defaultPortfolio = portfolios.length > 0 ? portfolios[0] : null;
  }
  const [activePortfolio, setActivePortfolio] = useState(defaultPortfolio);
  const [portfolioData, setPortfolioData] = useState<(Report | EthReport)[]>(
    defaultPortfolio ? defaultPortfolio.data : [],
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showRenameConfirm, setShowRenameConfirm] = useState(false);
  const [showCreatePortfolio, setShowCreatePortfolio] = useState(false);
  const handleError = useErrorHandler();

  const handleActivePortfolioChange = async (id: number) => {
    const activePortfolio = portfolios.find((portfolio) => portfolio.id === id);
    if (!activePortfolio) {
      setPortfolioData([]);
    } else {
      setPortfolioData(activePortfolio.data);
    }
    setActivePortfolio(activePortfolio);
  };

  const handleAddressDelete = async (address: string) => {
    try {
      if (!activePortfolio) {
        return;
      }
      const updatedPortfolioData = portfolioData.filter((portfolio) => {
        return portfolio.addressInfo.address !== address;
      });
      updatePortfolios(
        portfolios.map((portfolio) => {
          if (portfolio.id === activePortfolio.id) {
            return {
              ...portfolio,
              data: updatedPortfolioData,
            };
          }
          return portfolio;
        }),
      );

      setPortfolioData(updatedPortfolioData);
      toast("Address removed from portfolio");
      await axios.delete(`/api/portfolio/${activePortfolio.id}/address/${address}`);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeletePortfolio = async () => {
    try {
      if (!activePortfolio) {
        return;
      }
      axios.delete(`/api/portfolio/${activePortfolio.id}`);
      const udpatedPortfolioList = portfolios.filter((portfolio) => portfolio.id !== activePortfolio.id);
      updatePortfolios(udpatedPortfolioList);
      setShowDeleteConfirm(false);
      updateNumberOfPortfolios(user!.numberOfPortfolios - 1);
      setPortfolioData([]);
      if (udpatedPortfolioList.length > 0) {
        setActivePortfolio(udpatedPortfolioList[0]);
      } else {
        setActivePortfolio(null);
      }

      toast("Portfolio deleted successfully.");
    } catch (error) {
      handleError(error);
    }
  };

  const handleCreatePortfolio = async (name: string) => {
    try {
      const response = await axios.post("/api/portfolio", { name });
      updatePortfolios([...portfolios, response.data]);
      setActivePortfolio(response.data);
      setPortfolioData(response.data.data);
      setShowCreatePortfolio(false);
      updateNumberOfPortfolios(user!.numberOfPortfolios + 1);
      toast("New portfolio saved successfully.");
    } catch (error: unknown | AxiosError) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        toast.error(error.response.data.message);
      } else {
        handleError(error);
      }
    }
  };

  const handleUpdatePortfolioName = async (newName: string) => {
    try {
      if (!activePortfolio) {
        return;
      }

      axios.put(`/api/portfolio/${activePortfolio.id}`, {
        name: newName,
      });

      updatePortfolios(
        portfolios.map((portfolio) =>
          portfolio.id === activePortfolio.id ? { ...portfolio, name: newName } : portfolio,
        ),
      );
      setShowRenameConfirm(false);
      toast("Portfolio renamed successfully.");
    } catch (error: unknown | AxiosError) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        toast.error(error.response.data.message);
      } else {
        handleError(error);
      }
    }
  };

  const highRiskTransactions: Transaction[] = portfolioData.flatMap((tx) => {
    const clientAddress = tx.addressInfo.address;

    const highRisks: Transaction[] = Array.isArray(tx.highRisks)
      ? tx.highRisks
      : [...tx.highRisks.incoming, ...tx.highRisks.outgoing];
    return highRisks.map((highRisk) => {
      return {
        ...highRisk,
        clientAddress,
      };
    });
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setPrint(false);
    },
  });

  useEffect(() => {
    if (print) {
      handlePrint();
    }
  }, [print]);

  return (
    <Grid container direction="column" padding={4}>
      <PaymentBanner
        hasAccess={hasAccess}
        title="Upgrade your plan to access Hoptrail's screening and portfolio tools"
      />
      <Title>Portfolio</Title>
      <ToastContainer />
      <PortfolioActionBar
        onCreatePortfolio={() => setShowCreatePortfolio(true)}
        onRenamePortfolio={() => setShowRenameConfirm(true)}
        onDeletePortfolio={() => setShowDeleteConfirm(true)}
        onPortfolioChange={(portfolioId: number) => handleActivePortfolioChange(portfolioId as number)}
        onExportToPdf={() => setPrint(true)}
        user={user}
        highRiskTransactions={highRiskTransactions}
        activePortfolio={activePortfolio}
        portfolios={portfolios}
        portfolioData={portfolioData}
      />

      <Grid container item marginTop={4}>
        {portfolios.length > 0 && activePortfolio ? (
          <Grid container>
            <PortfolioDetail
              portfolioData={portfolioData}
              handleAddressDelete={handleAddressDelete}
              highRiskTransactions={highRiskTransactions}
            />
            <div style={{ display: "none" }}>
              <div ref={componentRef}>
                {print && (
                  <PortfolioDetailPrint
                    portfolioData={portfolioData}
                    name={activePortfolio.name}
                    highRiskTransactions={highRiskTransactions}
                  />
                )}
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item xs={12} textAlign="center">
            <Section>No portfolios found. Please create new one.</Section>
          </Grid>
        )}
      </Grid>
      {showCreatePortfolio && (
        <CreatePortfolio
          onCreate={(newName: string) => handleCreatePortfolio(newName)}
          onCancel={() => setShowCreatePortfolio(false)}
        />
      )}
      {showRenameConfirm && (
        <RenamePortfolio
          onRename={(newName) => handleUpdatePortfolioName(newName)}
          onCancel={() => setShowRenameConfirm(false)}
        />
      )}
      {showDeleteConfirm && (
        <DeletePortfolio onCancel={() => setShowDeleteConfirm(false)} onDelete={handleDeletePortfolio} />
      )}
    </Grid>
  );
};

export default Portfolio;
